import React, { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import * as yup from 'yup';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import SmartButtonIcon from '@mui/icons-material/SmartButton';
import RemoveIcon from '@mui/icons-material/Remove';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Button, Tooltip, Typography, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownHeaderV } from './DropdownHeader';
import { ColorPaletteTooltip } from './ColorPaletteTooltip';
import image from '../../../../common/components/images';
import { TDispatch, TodoSuccessResponse } from '../../../../common/utils/types.d';
import { RootState } from '../../../../redux/store';
import { TUpdateUserLoggedPasswordReducer } from '../../../../redux/userPermissions/user/types.d';
import {
  clearUserLoggedUpdatePasswordListPage,
  userLoggedPasswordUpdatePage,
} from '../../../../redux/userPermissions/user/actionCreator';
import { decryptData } from '../../../../common/utils/functions';
import { Context } from '../../../../common/utils/userContext';

type THeader = {
  height?: string;
  bgColor?: string;
  headerType?: number;
};

// componente de styled component para el header del layout
const Header = styled.header<THeader>`
  grid-area: header;
  background-color: #fff;
  color: #171717;
  display: flex;
  box-shadow: none;
  height: ${({ headerType }) =>
    headerType === 1 ? '100px' : `${headerType === 3 ? '45px' : '60px'}`};
  justify-content: center;
  align-items: center;
  /* z-index: 1700; */
`;

const SecondHeader = styled.header`
  height: 65px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  box-shadow: none;
  z-index: 1;
`;

const ThirdHeader = styled.header<{ bgColor?: string }>`
  height: 5px;
  background-color: ${({ bgColor }) => bgColor || '#fff'};
  display: flex;
  flex-direction: row;
  box-shadow: 0px 3px 5px 0px #3939392b;
  z-index: 1;
`;

export const LogoImage = styled.img`
  height: 40px;
  width: 100px;
  margin: 10px 0 0 15px;
  opacity: 1;
`;

export const NewHeader: FC<any> = ({
  loggedUser,
  isOpen,
  toggleAside,
  headerType,
  closeAllExpansion,
  handleSession,
  gradientHeader,
  colorHeader,
}) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);
  const [open, setOpen] = useState(false);
  const [userCredentials, setUserCredentials] = useState({ userName: '', userType: '' });
  const [openModal, setOpenModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const LogoBonusColor = image('logoBonusColor');

  const { token } = useContext(Context);

  const handleMouseEnter = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleMouseLeave = () => {
    setOpen(false);
  };

  /**
   * Schema de validaciones de campos
   */
  const schema = yup
    .object({
      password: yup.string().required('La contraseña es requerida'),
      newPassword: yup
        .string()
        .required('La nueva contraseña es requerida')
        .min(8, 'Mínimo 8 caracteres')
        .test('has-uppercase', 'Una mayúscula', (value) => /[A-Z]/.test(value))
        .test('has-number', 'Un número', (value) => /(?=.*\d)/.test(value))
        .matches(/^(?=.*[@$!%*_.?&])/, 'Un carácter especial'),
      confirmPass: yup
        .string()
        .oneOf([yup.ref('newPassword')], 'Las contraseñas deben coincidir')
        .required('La confirmación de la contraseña es requerida'),
    })
    .required();

  /*
    dispatch
    */
  const dispatch = useDispatch();

  /**
   * Dispatch de updatePassword
   */
  const userLoggedUpdatePasswordDispatch: TUpdateUserLoggedPasswordReducer = useSelector(
    (dataState: RootState) => dataState?.loggedPasswordUpdated
  );

  /**
   * useForm
   */
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const sendConfirm = () => {
    setOpenModal(!openModal);
    setOpen(false);
    reset({ password: '', newPassword: '', confirmPass: '' });
  };
  /**
   * Función de validar contraseñas
   */
  const validatePassword = () => {
    setTimeout(() => {
      dispatch(
        userLoggedPasswordUpdatePage({
          password: watch('password'),
          newPassword: watch('confirmPass'),
          token: `${token}`,
        }) as TDispatch
      );
      setLoading(true);
    }, 500);
  };

  /**
   * Función con array de mensajes de validación de newPassword
   */
  const validateAll = async (values: { newPassword: string }) => {
    try {
      await schema.validate(values, { abortEarly: false });
      return {}; // No hay errores
    } catch (error: any) {
      const errores: any = {};

      error.inner.forEach((e: { path: string | number; message: string }) => {
        if (!errores[e.path]) {
          errores[e.path] = [];
        }
        errores[e.path].push(e.message);
      });
      return errores; // Devuelve un objeto con todos los errores
    }
  };

  /**
   * UseEffect pendiente para validar a newPassword
   */
  const newPassword = watch('newPassword');
  useEffect(() => {
    validateAll({ newPassword }).then((errores: { newPassword: string[] }) => {
      if (Object.keys(errores).length) {
        setValidationErrors(errores?.newPassword);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword]);

  /**
   * UseEffect pendiente a nueva contraseña con limpieza de campos
   */
  useEffect(() => {
    if (loading)
      if (
        userLoggedUpdatePasswordDispatch?.userLoggedUpdatedPassword &&
        !userLoggedUpdatePasswordDispatch?.error
      ) {
        reset({
          password: '',
          newPassword: '',
          confirmPass: '',
        });
        dispatch(clearUserLoggedUpdatePasswordListPage() as TDispatch);
        toast.success('Contraseña actualizada exitosamente', {
          hideProgressBar: true,
        });
        setOpenModal(false);
      } else if (
        !userLoggedUpdatePasswordDispatch?.userLoggedUpdatedPassword &&
        userLoggedUpdatePasswordDispatch?.error
      ) {
        const message = userLoggedUpdatePasswordDispatch?.error?.response
          ? (userLoggedUpdatePasswordDispatch?.error?.response?.data as TodoSuccessResponse)
              ?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearUserLoggedUpdatePasswordListPage() as TDispatch);
      }
  }, [
    userLoggedUpdatePasswordDispatch?.userLoggedUpdatedPassword,
    userLoggedUpdatePasswordDispatch?.error,
    dispatch,
    loading,
    reset,
  ]);

  /**
   * UseEffect para llamar todos los dispatch
   */
  useEffect(() => {
    dispatch(clearUserLoggedUpdatePasswordListPage() as TDispatch);
  }, [dispatch]);

  useEffect(() => {
    setUserCredentials({
      userName: decryptData(window.localStorage.getItem('vs1-unx') || '', '3573n035uN70K3n'),
      userType: decryptData(window.localStorage.getItem('vs1-tux') || '', '3573n035uN70K3n')?.split(
        ';'
      )[0],
    });
    schema.validate({ password: '', newPassword: '' }, { abortEarly: false }).catch(() => {
      validateAll({ newPassword: '' }).then((errores: { newPassword: string[] }) => {
        if (Object.keys(errores).length) {
          setValidationErrors(errores?.newPassword);
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name: string, location?: string) => {
    const avatarName = name || 'Usuario Bonus plus';
    return {
      sx: {
        bgcolor: location === 'header' ? theme.palette.primary.main : stringToColor(name),
        color: location === 'header' ? theme.palette.common.white : '#fff',
        fontSize: location === 'header' ? '0.95rem' : '1.25',
        width: location === 'header' ? '35px' : '45px',
        height: location === 'header' ? '35px' : '45px',
        marginTop: location === 'header' && '0',
        fontWeight: 'bold',
      },
      children: `${avatarName.split(' ')[0][0].toUpperCase()}${
        avatarName.split(' ')[1] ? avatarName.split(' ')[1][0].toUpperCase() : ''
      }`,
    };
  };

  const styles = {
    grow: {
      flexGrow: 1,
      backgroundColor: '#ffffff',
      position: 'sticky',
    },
    sectionDesktop: {
      width: '100%',
      display: 'flex',
      justifyContent: 'end',
      margin: headerType === 1 ? '6px' : `${headerType === 3 ? '45px 0 0 10px' : '30px 0 0 10px'}`,
    },
    content: {
      display: 'flex',
      with: '50%',
      minHeight: '30px',
      height: '30px',
      paddingLeft: '10px',
    },
    logo: {
      width: '38%',
    },
    logoLg: {
      display: 'block',
    },
  };

  return (
    <Header headerType={headerType}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', boxShadow: 'none' }}>
        <AppBar
          sx={{
            height: '60px',
            background: '#fff',
            position: 'inherit',
            boxShadow: 'none',
            marginBottom: 'auto',
          }}>
          <Toolbar style={styles.content}>
            {headerType === 1 ? (
              <Typography variant="h6" sx={{ color: '#fff' }}>
                COOPHUMANA
              </Typography>
            ) : (
              <>
                <div
                  style={{
                    width: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: headerType === 3 ? '40px 0 0 0' : '30px 0 0 10px',
                    zIndex: 999,
                  }}>
                  <Link to="/admin">
                    <LogoImage src={LogoBonusColor} alt="exampleLogo" />
                  </Link>
                </div>
              </>
            )}
            <Box sx={styles.sectionDesktop}>
              {window.location.pathname === '/' ? <ColorPaletteTooltip /> : null}
              <IconButton
                aria-label="show 4 new mails"
                color={headerType && headerType === 1 ? 'inherit' : 'primary'}>
                <Badge variant="dot">
                  <MailIcon
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                  />
                </Badge>
              </IconButton>
              <IconButton
                aria-label="show 17 new notifications"
                color={headerType === 1 ? 'inherit' : 'primary'}>
                <Badge variant="dot">
                  <NotificationsIcon
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                  />
                </Badge>
              </IconButton>
              <DropdownHeaderV
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                open={open}
                openModal={openModal}
                sendConfirm={sendConfirm}
                userCredentials={userCredentials}
                headerType={headerType}
                anchorEl={anchorEl}
                stringAvatar={stringAvatar}
                handleSession={handleSession}
                errors={errors}
                schema={schema}
                register={register}
                control={control}
                handleSubmit={handleSubmit}
                validatePassword={validatePassword}
                updateLoading={userLoggedUpdatePasswordDispatch?.loading}
                validationErrors={validationErrors}
              />
            </Box>
          </Toolbar>
        </AppBar>
        {headerType === 1 && (
          <SecondHeader>
            <Button
              sx={{
                width: '30px',
                height: '30px',
                margin: '10px 0 0 10px',
                borderRadius: '5px',
                minWidth: '30px',
                backgroundColor: 'none',
              }}
              onClick={() => toggleAside()}>
              <MenuIcon color={!colorHeader ? 'inherit' : 'primary'} />
            </Button>
            <div
              style={{
                width: isOpen ? 'calc(100% - 285px)' : '100%',
                display: 'flex',
                justifyContent: 'center',
                margin: '0 40px 0 0',
                zIndex: 999,
              }}>
              <Link to="/">
                <LogoImage src={LogoBonusColor} alt="exampleLogo" />
              </Link>
            </div>
          </SecondHeader>
        )}
        {headerType === 3 && (
          <ThirdHeader bgColor={theme.palette.primary.main}>
            <div
              style={{
                display: isOpen ? 'flex' : 'none',
                marginLeft: '10px',
                width: '10px',
                justifyContent: 'flex-end',
              }}>
              <Tooltip title="Contraer todo" placement="right">
                <Button
                  sx={{
                    width: '15px',
                    height: '5px',
                    zIndex: 9999999999999,
                    // backgroundColor: '#def7f2',
                    borderRadius: '5px',
                    marginBottom: '15px',
                    minWidth: '10px',
                    padding: '0',
                  }}
                  onClick={() => closeAllExpansion()}>
                  <RemoveIcon
                    color={!colorHeader ? 'inherit' : 'primary'}
                    sx={{ fontSize: '24px', color: 'rgb(0,31,70)' }}
                  />
                </Button>
              </Tooltip>
            </div>
          </ThirdHeader>
        )}
      </div>
    </Header>
  );
};
