import * as yup from 'yup';

const schema = yup
  .object({
    idDocumentType: yup.number().required('Campo requerido'),
    identificationNumber: yup
      .number()
      .typeError('Este campo es requerido')
      .required('Este campo es requerido')
      .test(
        'is-between-8-and-11-digits',
        'Número de identificación debe tener entre 8 y 11 dígitos',
        (value) => {
          if (typeof value !== 'number') {
            return false;
          }
          const stringValue = String(Math.floor(value));
          const digitCount = stringValue.length;
          return digitCount >= 8 && digitCount <= 11;
        }
      ),

    idGender: yup.number().nullable().optional(),
    thirdPartyBeneficiary: yup.object({
      idThirdParty: yup.number().required('Campo requerido'),
    }),
    firstName: yup.string().nullable().optional(),
    middleName: yup.string().nullable().optional(),
    firstSurname: yup.string().nullable().optional(),
    secondSurname: yup.string().nullable().optional(),
    address: yup.object({
      neighBorhood: yup.string().optional(),
      idAddressSecondStreetType: yup.number().nullable().optional(),
      idAddressFirstStreetType: yup.number().nullable().optional(),
      secondStreetNumber: yup
        .string()
        .nullable()
        .test('is-number', 'Solo se permiten números', (value) => {
          if (!value) return true;
          return /^\d+$/.test(value);
        }),
      thirdStreetNumber: yup
        .string()
        .nullable()
        .test('is-number', 'Solo se permiten números', (value) => {
          if (!value) return true;
          return /^\d+$/.test(value);
        }),
      firstStreetNumber: yup
        .string()
        .nullable()
        .test('is-number', 'Solo se permiten números', (value) => {
          if (!value) return true;
          return /^\d+$/.test(value);
        }),
      idDepartment: yup.number().nullable().optional(),
      description: yup.string().optional(),
      idCity: yup.number().nullable().optional(),
    }),
    cellPhone: yup
      .string()
      .nullable()
      .test('is-number', 'Solo se permiten números', (value) => {
        if (!value) return true;
        return /^\d+$/.test(value);
      }),
    email: yup.string().email('Correo no válido').optional(),
    telephone: yup.string().optional(),
  })
  .required();

export default schema;
