import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import UploadIcon from '@mui/icons-material/Upload';
import { Box, Button, CircularProgress, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { IBeneficiariesRegister } from './types.d';
import { InputField, SelectAdapter } from '../../../../../common/components/formAdapters';
import { constructorName, onlyLetters, onlyNumbers } from '../../../../../common/utils/functions';
import { BulkLoad } from '../../../containers/beneficiaries/bulkLoad';
import { TransitionsModal } from '../../../../../common/components/modal/index';
import { TitleComponent } from '../../../../../common/components/titles/styled';

export const BeneficiaryRegisterV: React.FC<IBeneficiariesRegister> = ({
  handleRegister,
  watchDepartment,
  watchIdStreetTypeOne,
  control,
  schema,
  dataCities,
  dataDepartments,
  dataDocumentType,
  dataGender,
  dataMembership,
  dataStreetTypes,
  createLoading,
  updateLoading,
  idBeneficiary,
  handleUpdate,
  sendConfirm,
  openModal,
  login,
}) => {
  const theme = useTheme();
  const styles = {
    header: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      fontSize: '1.8rem',
      textAlign: 'left',
      fontFamily: 'Poppins-Regular',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.5rem',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    formBox: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: '#fff',
      borderRadius: '16px',
      boxShadow: '0px 3px 6px #00000029',
      minWidth: '305px',
    },
    buttonSubmit: {
      display: 'flex',
      alignContent: 'center',
      width: '135px',
      height: '35px',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '@media screen and (max-width: 899px)': {
        width: '200px',
      },
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);',
    },
    bulkButtonSubmit: {
      display: 'flex',
      alignContent: 'center',
      width: '170px',
      height: '35px',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '@media screen and (max-width: 899px)': {
        width: '200px',
      },
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);',
    },
    bulkButtonBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '15px',
    },
    buttonBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      '@media screen and (max-width: 899px)': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  };
  return (
    <Box
      style={{
        width: '100%',
      }}>
      {!idBeneficiary && (
        <Box sx={styles.bulkButtonBox}>
          <Button
            type="button"
            variant="contained"
            onClick={() => sendConfirm('bulkLoad')}
            startIcon={<UploadIcon fontSize="small" />}
            sx={{ ...styles.bulkButtonSubmit, color: '#fff' }}>
            Carga Masiva
          </Button>
        </Box>
      )}
      <Box sx={styles.formBox}>
        <Box
          sx={{
            display: 'flex',
            padding: '25px',
            flexDirection: 'column',
          }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingBottom: '10px',
            }}>
            <TitleComponent>
              {idBeneficiary ? 'Editar beneficiario' : 'Registro de beneficiarios'}
            </TitleComponent>
          </Box>
          <Box sx={{ paddingLeft: '15px', paddingRight: '15px' }}>
            <Typography variant="h5" color={theme.palette.primary.main} sx={{ pb: '15px' }}>
              Información del beneficiario
            </Typography>
            <Box>
              <Grid container spacing={{ xs: 2, sm: 2, md: 2 }}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <SelectAdapter
                    name="idDocumentType"
                    label="Tipo de identificación"
                    key="idDocumentType"
                    control={control}
                    options={
                      (dataDocumentType || [])?.map((x) => ({
                        value: x?.idDocumentType,
                        label: x?.name,
                      })) || []
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    onKeyDown={onlyNumbers}
                    label="Número de identificación"
                    name="identificationNumber"
                    schema={schema.fields?.identificationNumber}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <SelectAdapter
                    name="idGender"
                    label="Género"
                    key="idGender"
                    control={control}
                    options={
                      (dataGender || [])?.map((x) => ({
                        value: x?.idGender,
                        label: x?.name,
                      })) || []
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <SelectAdapter
                    name="thirdPartyBeneficiary.idThirdParty"
                    label="Membresía"
                    key="thirdPartyBeneficiary.idThirdParty"
                    disabled={login?.includes('Membresia')}
                    control={control}
                    options={
                      (dataMembership || [])?.map((x) => ({
                        value: x?.idThirdParty,
                        label: !x?.businessName
                          ? constructorName([
                              x?.firstName,
                              x?.middleName,
                              x?.firstSurname,
                              x?.secondSurname,
                            ])
                          : x?.businessName,
                      })) || []
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Primer nombre"
                    name="firstName"
                    schema={schema.fields?.firstName}
                    control={control}
                    onKeyDown={onlyLetters}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Segundo nombre"
                    name="middleName"
                    schema={schema.fields?.middleName}
                    control={control}
                    onKeyDown={onlyLetters}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Primer apellido"
                    name="firstSurname"
                    schema={schema.fields?.firstSurname}
                    control={control}
                    onKeyDown={onlyLetters}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Segundo apellido"
                    name="secondSurname"
                    schema={schema.fields?.secondSurname}
                    control={control}
                    onKeyDown={onlyLetters}
                  />
                </Grid>
              </Grid>
            </Box>

            <Typography
              variant="h5"
              color={theme.palette.primary.main}
              sx={{ pb: '15px', pt: '15px' }}>
              Ubicación y contacto
            </Typography>
            <Box sx={{ flexGrow: 1, marginBottom: '8px' }}>
              <Grid container spacing={{ xs: 2, sm: 2, md: 2 }}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <SelectAdapter
                    name="address.idDepartment"
                    label="Departamento"
                    key="address.idDepartment"
                    control={control}
                    options={
                      (dataDepartments || [])?.map((x) => ({
                        value: x?.idDepartment,
                        label: x?.name?.toUpperCase(),
                      })) || []
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <SelectAdapter
                    name="address.idCity"
                    label="Ciudad"
                    key="address.idCity"
                    control={control}
                    options={
                      (dataCities || [])
                        ?.filter((x) => x?.idDepartment === Number(watchDepartment))
                        .map((x) => {
                          return {
                            value: x?.idCity,
                            label: x?.name?.toUpperCase(),
                          };
                        }) || []
                    }
                    disabled={!watchDepartment}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Barrio"
                    name="address.neighBorhood"
                    schema={schema.fields?.address?.neighBorhood}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <SelectAdapter
                    name="address.idAddressFirstStreetType"
                    label="Tipo de dirección 1"
                    control={control}
                    options={
                      (dataStreetTypes || [])?.map((x) => ({
                        value: x?.idStreetType,
                        label: x?.name,
                      })) || []
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Número"
                    name="address.firstStreetNumber"
                    schema={schema.fields?.address.firstStreetNumber}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <SelectAdapter
                    name="address.idAddressSecondStreetType"
                    label="Tipo de dirección 2"
                    control={control}
                    options={
                      (dataStreetTypes || [])
                        ?.filter((x) => x?.idStreetType !== Number(watchIdStreetTypeOne))
                        .map((x) => ({
                          value: x?.idStreetType,
                          label: x?.name,
                        })) || []
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Número 2"
                    name="address.secondStreetNumber"
                    schema={schema.fields?.address?.secondStreetNumber}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Número 3"
                    name="address.thirdStreetNumber"
                    schema={schema.fields?.address?.thirdStreetNumber}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Detalle"
                    name="address.description"
                    schema={schema.fields?.address?.description}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Celular"
                    name="cellPhone"
                    schema={schema.fields?.cellPhone}
                    control={control}
                    onKeyDown={onlyNumbers}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Teléfono"
                    name="telephone"
                    schema={schema.fields?.telephone}
                    control={control}
                    onKeyDown={onlyNumbers}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Correo electrónico"
                    name="email"
                    schema={schema.fields?.email}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={styles.buttonBox}>
              <Tooltip title={idBeneficiary ? 'Actualizar beneficiario' : 'Registrar beneficiario'}>
                <Button
                  sx={{ ...styles.buttonSubmit, color: '#fff' }}
                  disabled={createLoading || updateLoading}
                  type="button"
                  onClick={idBeneficiary ? handleUpdate : handleRegister}
                  startIcon={createLoading || updateLoading ? null : <SaveIcon fontSize="small" />}>
                  {createLoading || updateLoading ? (
                    <CircularProgress size={15} sx={{ color: 'white' }} />
                  ) : (
                    <Typography fontSize="14px" sx={{ display: 'flex', alignItems: 'center' }}>
                      {idBeneficiary ? 'ACTUALIZAR' : 'CREAR'}
                    </Typography>
                  )}
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
      <TransitionsModal
        state={openModal?.bulkLoad}
        overflow="auto"
        handleCloseModal={() => {
          sendConfirm('bulkLoad');
        }}
        width="40%"
        minWidth="350px"
        minHeight="590px">
        <BulkLoad />
      </TransitionsModal>
    </Box>
  );
};
